
import { defineComponent, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
import collapseTable from "./collapseTable.vue";
import useDealerStatusTracking from "@/hooks/payment/PaymentTracking/useDealerStatusTrackingOverview";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  name: "DealerSetting",
  props: {},
  setup() {
    const activeKey = ref<string>("1");
    const {
      search,
      reset,
      searchRound,
      query,
      NormalDelearData,
      RiskDelearData,
      column,
      roundList,
      entityRef,
      isFavoriteVisible,
      showFavorite,
      closeFavorite,
      saveFavorite,
      favoriteColumns,
      favoriteData,
      customRow,
      deleteFavorite,
      getRowClass,
    } = useDealerStatusTracking();

    return {
      open,
      search,
      reset,
      searchRound,
      query,
      activeKey,
      entityRef,
      roundList,
      NormalDelearData,
      RiskDelearData,
      column,
      isFavoriteVisible,
      showFavorite,
      closeFavorite,
      saveFavorite,
      favoriteColumns,
      favoriteData,
      customRow,
      deleteFavorite,
      getRowClass,
      toThousandsAndTwoDecimal,
    };
  },
  components: {
    RightOutlined,
    collapseTable,
    EntitySelector
  },
});

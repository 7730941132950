import { computed, onMounted, reactive, ref, toRaw } from "vue";
import moment from "moment";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import {
  getDealerPayoutCondition,
  getRoundListByDate,
  fetchListDealerSetting,
  saveConditionDealerStatus,
  deleteCondition,
  conditionAllDealerStatus,
  fetchListDealerSettingForTracking,
} from "@/API/payment";
import { message } from "ant-design-vue";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import { DataProps } from "@/views/Payment/Controlling/Dealer/DealerStatusTracking/type";
export interface FavoriteTableData {
  id: string;
  year: string;
  month: string;
  payoutRound: string;
  entity: string;
}
const useDealerStatusTracking = () => {
  const entityRef = ref();
  // 表头
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerNameCN",
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWOVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "amountWVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
    },
  ]);
  // 查询条件
  const query = reactive({
    year: "",
    month: "",
    payoutRound: "",
    entity: "MBCL",
    dealerName: "",
    dealerCode: "",
    region: "",
    dealerGroup: "",
  });
  // 获取折叠面板表格数据
  const res = ref();
  const NormalDelearData = computed(() => {
    return res.value?.normalDealer;
  });
  const RiskDelearData = computed(() => {
    return res.value?.riskDealer;
  });
  // 查询round列表
  const roundRes = ref();
  const roundList = computed(() => {
    return roundRes.value ? roundRes.value : [];
  });
  const searchRound = () => {
    roundRes.value = null;
    query.payoutRound = "";
    return new Promise((resolve, reject) => {
      getRoundListByDate({
        params: {
          month: "0",
          year: "0",
          type: "DEALER",
        },
      }).then((res) => {
        roundRes.value = res;
        query.payoutRound = res[0];
        resolve(res);
      });
    });
  };
  const search = () => {
    fetchListDealerSettingForTracking(query.payoutRound, query.entity)
      .then((data) => {
        res.value = data;
      })
      .catch(() => {
        res.value = [];
      });
    // const _query = {
    //   year: null,
    //   month: null,
    //   payoutRound: query.payoutRound,
    //   entity: query.entity,
    //   dealerName: null,
    //   dealerCode: null,
    //   region: null,
    //   dealerGroup: null,
    // };
    // fetchListDealerSetting(toRaw(_query))
    //   .then((data) => {
    //     res.value = data;
    //   })
    //   .catch(() => {
    //     res.value = [];
    //   });
  };
  const initCondition = () => {
    return new Promise((resolve) => {
      getDealerPayoutCondition().then((res) => {
        query.month =
          res.year + "-" + (res.month > 10 ? res.month : "0" + res.month);
        query.year = res.year.toString();
        resolve(res);
      });
    });
  };
  onMounted(() => {
    console.log("onMounted");
    initCondition().then(() => {
      searchRound().then(() => {
        search();
      });
    });
  });
  const reset = () => {
    roundRes.value = null;
    query.payoutRound = "";
    // entityRef.value.reset()
    initCondition().then(() => {
      searchRound().then(() => {
        search();
      });
    });
  };

  const isFavoriteVisible = ref(false);
  const saveFavorite = () => {
    const params = {
      year: moment(query.year).format("YYYY"),
      month: moment(query.month).format("M"),
      payoutRound: query.payoutRound,
      entity: query.entity,
    };
    saveConditionDealerStatus(params).then(() => {
      message.success("Sucessfully Saved!");
    });
  };
  const favoriteColumns = ref([
    {
      title: "Payout Year",
      dataIndex: "year",
    },
    {
      title: "Payout Month",
      dataIndex: "month",
    },
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
    },
    {
      title: "Entity",
      dataIndex: "entity",
    },
    {
      title: "Operation",
      key: "operation",
      slots: { customRender: "action" },
    },
  ]);
  const favoriteData = ref<FavoriteTableData[]>([]);
  const getFavoriteList = () => {
    conditionAllDealerStatus().then((data: any) => {
      favoriteData.value = data;
    });
  };
  const showFavorite = () => {
    isFavoriteVisible.value = true;
    getFavoriteList();
  };
  const closeFavorite = () => {
    isFavoriteVisible.value = false;
  };
  const customRow = (
    record: FavoriteTableData,
    index: number
  ): { onClick: Function } => {
    return {
      onClick: () => {
        query.year = record.year;
        query.month = record.month;
        query.payoutRound = record.payoutRound;
        query.entity = record.entity;
        closeFavorite();
        search();
      },
    };
  };
  const deleteFavorite = (record: FavoriteTableData) => {
    deleteCondition(record.id).then(() => {
      message.success("Sucessfully Delete!");
      getFavoriteList();
    });
  };
  // 斑马纹
  const getRowClass = useStripedRowClass<DataProps>();
  return {
    search,
    reset,
    searchRound,
    query,
    column,
    NormalDelearData,
    RiskDelearData,
    entityRef,
    roundList,
    isFavoriteVisible,
    showFavorite,
    closeFavorite,
    saveFavorite,
    favoriteColumns,
    favoriteData,
    customRow,
    deleteFavorite,
    getRowClass,
  };
};
export default useDealerStatusTracking;
